<template>
  <div>
    <b-modal
      v-model="showModal"
      title="Share"
      header-bg-variant="info"
      header-text-variant="default"
      hide-header-close
      no-close-on-backdrop
      centered
    >
      <b-container fluid>
        <b-button class="m-2" variant="outline-secondary">
          <b-icon-facebook
            class="heart-style mr-2"
            font-scale="1"
            variant="primary"
          ></b-icon-facebook>
          <ShareNetwork
            network="facebook"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            Facebook
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary">
          <b-icon-messenger
            class="heart-style mr-2"
            font-scale="1"
            variant="primary"
          ></b-icon-messenger>
          <ShareNetwork
            network="messenger"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            Messenger
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary">
          <b-icon-twitter
            class="heart-style mr-2"
            font-scale="1"
            variant="info"
          ></b-icon-twitter
          ><ShareNetwork
            network="Twitter"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            Twitter
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary">
          <b-icon-whatsapp
            class="heart-style mr-2"
            font-scale="1"
            variant="info"
          ></b-icon-whatsapp>
          <ShareNetwork
            network="whatsapp"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            <!-- description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite" -->
            Whatsapp
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary">
          <b-icon-telegram
            class="heart-style mr-2"
            font-scale="1"
            variant="info"
          ></b-icon-telegram
          ><ShareNetwork
            network="telegram"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            Telegram
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary">
          <b-icon-linkedin
            class="heart-style mr-2"
            font-scale="1"
            variant="primary"
          ></b-icon-linkedin
          ><ShareNetwork
            network="LinkedIn"
            :url="dataUrl"
            title="Sharing with you a great writing I just found! Read and enjoy."
          >
            LinkedIn
          </ShareNetwork></b-button
        >

        <b-button class="m-2" variant="outline-secondary" @click="copyLink">
          <b-icon-files
            class="heart-style mr-2"
            font-scale="1"
            variant="primary"
          ></b-icon-files>
          <span class="ml-1" style="color: #007bff; text-decoration: none"
            >Copy link</span
          ></b-button
        >
      </b-container>

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-primary"
          @click="$emit('closedModal')"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BIconFacebook,
  BIconMessenger,
  BIconTwitter,
  BIconWhatsapp,
  BIconTelegram,
  BIconLinkedin,
  BIconFiles,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BIconFacebook,
    BIconMessenger,
    BIconTwitter,
    BIconWhatsapp,
    BIconTelegram,
    BIconLinkedin,
    BIconFiles,
  },

  props: {
    link: String,
  },

  data() {
    return {
      showModal: true,
    };
  },

  computed: {
    dataUrl() {
      return `${window.location.origin}/post/${this.link}`;
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.dataUrl);
      this.$bvToast.toast("Link has been copied!", {
        title: "Link copied",
        autoHideDelay: 2000,
        variant: "info",
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.person-hover {
  color: black;

  &:hover {
    color: black;
  }
}
</style>
